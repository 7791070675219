@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap');

body {
    font-family: 'Fira Sans', sans-serif;
}

.btns-add {
    padding: 0;
    margin: 0;

    li {
        list-style-type: none;
        display: inline-block;
        margin: 0 10px 5px 0;

        &:last-child {
            margin-right: 0;
        }
    }
}

.btn-light {
    background: #A2E8C2;
    color: #288850;

    &:hover,
    &:focus,
    &:active {
        background: #8ADCAF;
        color: #288850;
    }
}
.values_exp {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
        display: inline-block;
        margin: 0 0 10px 0;
        background: #63A581;
        border-radius:0;
        padding:6px 18px 6px 18px;
        color: #fff;
        position: relative;
        min-width: 25%; 
        border-radius: 10px;   
        margin-right: 6px; 

        &.active{background: #1D6F42;}

        label {
            font-size:14px;
            display: block;
            font-weight: 400;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        span {
            font-size:24px;
            display: block;
            font-weight: 700;
        }

        svg {
            position: absolute;
            left: 5px;
            top:16px;
            fill: #aee1c3;
            height: 34px;
        }
    }
}

.values {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
        display: inline-block;
        margin: 0 0 10px 0;
        /* background: #F3F3F3; */
        border-radius:0;
        padding:6px 18px 6px 38px;
        color: #4E4E4E;
        position: relative;
        min-width: 25%;

        &:nth-child(1) {
            border-bottom:3px solid #20A8F2;     
            background-color:#20A8F2 ;       
            svg {
                fill: #fff;
            }
            label {
                color: #fff;
            }
            span {
                color: #fff;
            }
            &.active{border-color: #332E2E;}
            &:hover{background:#20A8F2;}
        }
        &:nth-child(2) {
            border-bottom: 3px solid #FB7E00;     
            background-color:#FB7E00 ;               
            svg {
                fill: #fff;
            }
            label {
                color: #fff;
            }
            span {
                color: #fff;
            }
            &.active{background:#FB7E00;border-color: #332E2E;}
            &:hover{background:#FB7E00;}
        }        
        &:nth-child(3) {
            margin-right: 0;
            border-bottom:3px solid #7ECD00;     
            background: #7ECD00;       
            svg {
                fill:#fff;
            }
            label {
                color:#fff;
            }
            span {
                color: #fff;
            }
           &.active{background:#7ECD00;border-color:#332E2E;}
           &:hover{background:#7ECD00;}
            
        }

        &:hover {
            color: #2E2E2D;
            background: #E6FFF1;

            /* svg {
                fill: #288850;
            } */
        }

        label {
            font-size:14px;
            display: block;
            font-weight: 400;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        span {
            font-size:24px;
            display: block;
            font-weight: 700;
        }

        svg {
            position: absolute;
            left: 5px;
            top:16px;
            fill: #aee1c3;
            height: 34px;
        }
    }
}

.calendar {
    background: url(../images/calendar.svg) no-repeat right center;
    padding-right: 45px;
    background-color: #fff;
}

.grn-bg-frm {
    background: #7ED5A2;
    border-radius: 10px;
    padding: 20px 20px 0 20px;

    .formlabel {
        background: #67B38D;
        border-radius: 10px;
        padding: 20px;
        color: #fff;
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 0;
        }

        span {
            display: block;
            font-weight: 700;
        }
    }
}

.grn-bg-frm2 {
    background: #67B38D;
    border-radius: 10px;
    padding: 20px 20px 0 20px;

    .formlabel {
        background: #67B38D;
        border-radius: 10px;
        padding-left: 20px;
        color: #fff;
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 0;
        }

        span {
            display: block;
            font-weight: 700;
        }
    }
}
.grey-bg-frm {
    background: #f6f5f5;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
}

.grey-border-frm {
    border-color: #cacaca !important;
}

.table-icons {
    color: #288850;

    i {
        margin: 0 5px;
        cursor: pointer;
    }
}

.md-700 {
    max-width: 700px;
}

.modal-content {
    .modal-header {
        h5 {
            font-size: 16px;
            font-weight: 500;
        }

        .close {
            font-size: 16px;
            top: 5px;
            right: 8px;
        }
    }

    .modal-footer {
        border-top: 0;
    }
}

span.bold {
    font-weight: 700;
}

.status-mess {
    color: #F24343;
    display: block;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0;
}

span.status {
    width: 100px;
    display: inline-block;
    border-radius: 6px;
    padding: 3px 0;
    text-align: center;
    margin-bottom: 2px;

    &.partially {
        background: #FFEEB8;
        color: #9A5454;
    }

    &.pending {
        background: #FBDACA;
        color: #9A5454;
    }

    &.sent {
        background: #FBDACA;
        color: #9A5454;
    }

    &.converted {
        background: #FBDACA;
        color: #9A5454;
    }

    &.expired {
        background: #FBDACA;
        color: #9A5454;
    }
}
.list-circle {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
}
.table-button {
    button.normal-btn{
        border-radius: 6px;
        background:#eaeaea; 
        border: 0 none;
        padding: 5px 12px;
        color: #1a3254;
        &:hover{
            background:#bec7d5;
        }
    }
    button.round-btn{
        border-radius: 6px;
        background:#eaeaea;
        margin-right: 10px;
        border: 0 none;
        border-radius: 50% !important;
        width: 40px;
        height: 40px;
        text-align: center;
        &.whatsapp{background: #01D46B;
            svg { fill:#fff;}
            &:hover{background:#0eb561;}
        }
        svg {
            fill:#1a3254;
        }
        &:hover{
            background:#bec7d5;
        }

        &.green {            

            &:hover {
                background: #59CC8D;
            }
        }

        &.green:hover {
            background: #7AE9AC;            
        }

        &.red {            

            &:hover {
                background: #F1BEBE;
            }
        }

        &.red:hover {
            background: #FCC6C6;
        }

        &.yellow {           

            &:hover {
                background: #DFC388;
            }
        }

        &.yellow:hover {
            background: #FDE3AB;
        }

        &.orange {            

            &:hover {
                background: #ECB18A;
            }

            a {
                color: #955124;
            }
        }
        &.orange:hover {
            background: #FFC097;
        }
    }
}

.table-responsive {
    overflow-x: auto !important;
}

.radio-btns {
    background: #DBDBDB;
    border-radius: 10px;
    padding: 10px 15px;

    label {
        margin-bottom: 0;
        font-weight: 400;
    }

    fieldset {
        margin-bottom: 0 !important;

        .form-control {
            background: none;
        }
    }
}

.user-det {
    background: #F6F6F6;
    border-radius: 6px;
    padding: 15px 15px;

    label {
        color: #BBBBBB;
    }
}

.user-settings {
    .card-header {
        background: #F6F6F6;
    }
}


/*dash board*/
.cstm-card {
    .card-header {
        padding: 1rem 1.25rem;
        font-weight: 500;
    }

    .card-body {
        min-height: 405px;
    }
}

.qck-link {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
        display: inline-block;
        margin: 12px;

        a {

            label {
                display: block;
                color: #343434;
                text-align: center;
                margin-top: 10px;
            }

            span {
                display: block;
                background: #E4FFF0;
                border-radius: 12px;
                padding: 20px;
            }

            &:hover {
                span {
                    background: #C4E9D5;
                }
            }
        }
    }

}
.qck-link1 {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align:right;

    li {
        display: inline-block;
        margin:0 0 0 15px;

        a {
            display: block;

            label {
                display: block;
                color: #343434;
                text-align: center;
                margin-top: 10px;
                font-size: 13px;
            }

            span {
                display: block;
                background: #eaeaea;
                border-radius: 12px;
                display: flex;
                height:65px;
                width:65px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 1px solid #eaeaea;
                box-shadow: 0px 1px 2px #00000029;
                margin: 0 auto;
            }

            &:hover {
                span {
                    background: #e9e9e9;
                    border: 1px solid #1A3254;
                }
            }
        }
    }

}


/*invoice create*/
.form-head {
    background: #EFEFEF;
    border-radius: 6px;
    padding: 15px 15px 0 15px;
}

.add-row {
    border-bottom: 1px solid #1D6F41;
    text-align: center;
    .login {
        background: #fff !important;        
        color: #000000;
        width: 10%;
        position: relative;
        bottom: -14px;  
        display: block;
        margin-left: auto;
        margin-right: auto;      
    }
    .btn {
        background: #fff;
        border: 1px solid #1D6F41;
        color: #1D6F41;
        width: 200px;
        position: relative;
        bottom: -20px;

        i {
            font-size: 18px;
            position: relative;
            top: 3px;
        }

        &:hover {
            background: #1D6F41;
            color: #fff;
        }
    }
}

.tbl-btn {
    color: #1D6F41;
    background: #EBEBEB;
    border: 1px solid #D8D8D8;
    border-radius: 6px;

    &:hover {
        background: #D6FFE7;
        color: #239155;
    }
}

.total {
    background: #EFEFEF;
    padding: 15px;
    border-radius: 14px;

    ul {
        margin: 0;
        padding: 0;

        li {
            display: block;
            border-bottom: 1px solid #C2C2C2;
            padding: 5px 0;

            &:last-child {
                border: 0;
            }

            label {
                display: inline-block;
                float: left;
            }

            span {
                display: block;
                text-align: right;
                margin-left: 150px;
            }
        }
    }
}

.count {
    color: #1D6F42;
}


.form-wrap{

    .header{
        h4{display: inline-block;margin-right: 25px;}
        .form-inline{display: inline-block;}
    }
}

.cstm-search{
    .search-label{float: none;margin-bottom: 0;}
}
.form-control:disabled, .form-control[readonly] {
    background-color: #f2f2f2;
    opacity: 1;
  }

  .cstm-tbl{
    
    .form-control{height:35px;}
    .btn{padding: 7px 0.75rem;}

 } 

 .table {
    font-size: 15px;
 }
 #sidebar-menu ul li a {
    font-size: 15px !important;
    /* font-weight: 500 !important; */
 }

.package{
    h1{font-size: 36px;color: #000000;font-weight:700;}
    h2{font-size: 18px;color: #000000;}
    h3{font-size: 22px;color: #38716A;font-weight: 500;}
    h4{font-size: 12px;color: #9B9B9B;font-weight: 400;}
}

.pointer {
    cursor: pointer;
}

@media (max-width: 1199px) {
    .values {
        margin-top: 25px;
    }
    .qck-link1 {
        text-align:left;
        margin-top:10px;
     li{ margin:10px 15px 0 0!important;}  
    
    }
}
@media (min-width: 1200px) {
    .form-wrap{

        .header{
            h4{display: inline-block;margin-right: 25px;}
            .form-inline{display: inline-block;
                .css-b62m3t-container{width: 250px;}
            }
        }
    }
    
}
@media (max-width:991px){
    .form-wrap{
display: block!important;
        .header{
            h4{display:block;margin-bottom:10px!important;}
            .form-inline{display:block;}
            .react-datepicker-wrapper{margin: 10px 0;}
        }
    }

}

/* following code to hide Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

